import { snakeCaseToCamelCase } from '@elo-kit/utils/nameStyle.utils'
import { createId } from '@elo-kit/utils/general.utils'

import { PAYMENT_FORMS, PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'
import { LOCALES } from '@elo-kit/constants/locales.constants'

import Card from 'shared/components/purchase-methods/assets/paymethods/Card.svg'
import AmEx from 'images/payment_methods/AmEx.svg'
import StripeCard from 'shared/components/purchase-methods/assets/paymethods/StripeCard.svg'
import PayPal from 'shared/components/purchase-methods/assets/paymethods/PayPal.svg'
import BankWire from 'shared/components/purchase-methods/assets/paymethods/BankWire.svg'
import BankWireDe from 'shared/components/purchase-methods/assets/paymethods/BankWireDe.svg'
import Sepa from 'shared/components/purchase-methods/assets/paymethods/Sepa.svg'
import Sofort from 'images/payment_methods/SofortDark.svg'
import PayLater from 'shared/components/purchase-methods/assets/paymethods/PayLater.svg'
import PayLaterDe from 'shared/components/purchase-methods/assets/paymethods/PayLaterDe.svg'
import P24 from 'shared/components/purchase-methods/assets/paymethods/P24.svg'
import ExtBalance from 'shared/components/purchase-methods/assets/paymethods/ExtBalance.svg'
import freeEn from 'shared/components/purchase-methods/assets/paymethods/free_en.svg'
import freeDe from 'shared/components/purchase-methods/assets/paymethods/free_de.svg'
import BankAccount from 'shared/components/purchase-methods/assets/paymethods/BankAccount.svg'
import BankAccountDe from 'shared/components/purchase-methods/assets/paymethods/BankAccountDe.svg'
import ApplePay from 'shared/components/purchase-methods/assets/paymethods/ApplePay.svg'
import GooglePay from 'shared/components/purchase-methods/assets/paymethods/GooglePay.svg'
import Ideal from 'shared/components/purchase-methods/assets/paymethods/Ideal.svg'
import IdealSepa from 'shared/components/purchase-methods/assets/paymethods/IdealSepa.svg'
import Klarna from 'images/payment_methods/klarna.svg'

type Attributes = {
  paymentForm: string
  src: string
  className?: string
  style: {
    height?: string | number
    maxHeight?: string | number
    marginRight?: string
    opacity?: number
  }
}

export const getPubKey = (paymentForm: string, providers: Record<string, string>): string => {
  const provider = providers[`${snakeCaseToCamelCase(paymentForm)}Provider`]

  if (provider === PAYMENT_PROVIDERS.elopageConnect) {
    return 'elopageConnectPubKey'
  }
  if (provider === PAYMENT_PROVIDERS.stripe) {
    return 'stripePubKey'
  }

  return ''
}

export const getPaymentMethodIcon = (paymentForm: string, cardProvider = PAYMENT_PROVIDERS.stripe) => {
  const formByCardProvider =
    cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect
      ? 'stripeCard'
      : paymentForm
  const iconName = paymentForm === PAYMENT_FORMS.card ? formByCardProvider : paymentForm

  const PAYMENT_METHODS_ICONS = {
    card: Card,
    stripeCard: [AmEx, StripeCard],
    paypal: PayPal,
    [PAYMENT_FORMS.bankWire]: I18n.locale === LOCALES.en ? BankWire : BankWireDe,
    sepa: Sepa,
    sofort: Sofort,
    [PAYMENT_FORMS.payLater]: I18n.locale === LOCALES.en ? PayLater : PayLaterDe,
    [PAYMENT_FORMS.p24]: P24,
    ext_balance: ExtBalance,
    free: I18n.locale === 'de' ? freeDe : freeEn,
    bank_account: I18n.locale === LOCALES.en ? BankAccount : BankAccountDe,
    [PAYMENT_FORMS.applePay]: ApplePay,
    [PAYMENT_FORMS.googlePay]: GooglePay,
    [PAYMENT_FORMS.ideal]: Ideal,
    [PAYMENT_FORMS.idealSepa]: IdealSepa,
    [PAYMENT_FORMS.klarna]: Klarna,
  }
  return PAYMENT_METHODS_ICONS[iconName]
}

export const createImg = (attributes: Attributes) => {
  const { paymentForm, src = '', className = '', style = {} } = attributes || {}
  return <img key={createId(paymentForm, src)} src={src} alt='logo' className={className} style={style} />
}
