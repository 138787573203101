import type { Response, ShallowResponse } from 'types/responses'
import type { Nullable } from 'types/helpers'
import { ApiClient, apiClient } from 'utils/requests.utils'

import type { Order, MoneyHolder, Invoice } from 'shop/types/orders'
import type { TicketUpgradeData } from 'types/upgrade-mappings'

interface ManageOrder {
  paymentForm: string
  moneyHolder: MoneyHolder
}

interface ChargeOrderResp {
  success: boolean
  error: string
  redirectLink: string
  clientSecret: string
  paymentMethod: number | string
}

interface OrdersReq {
  token: string
  username: string
}

interface ChangeOrderResp {
  clientSecret: Nullable<string>
  data: Order
  paymentMethod: Nullable<string>
  redirectLink: Nullable<string>
  paymentForm: string
}

interface NewOrder {
  invoice: Invoice
  order: Order
  props: {
    orderId: number
    orderToken: string
    payerAttrs: {
      city: string
      company: string
      country_code: string
      email: string
      first_name: string
      last_name: string
      phone: Nullable<string>
      street: string
      street_number: Nullable<string>
      vat_no: Nullable<string>
      zip: string
    }
    paymentPageTemplateId: number | string
  }
}

export interface CreateOrderResponse
  extends ShallowResponse<{
    redirectLink: string
    clientSecret: string
    paypalPaylaterAttributes?: {
      orderId: string
      returnUrl: string
      cancelUrl: string
    }
    token: string
  }> {}

const BASE_URL = (username) => `/shop/${username}/orders`

export const createOrdersApi = ({ GET, POST, PUT }: ApiClient) => ({
  fetchItem: (token: string, data: { username: string }) =>
    GET<Response<Order>>(`${BASE_URL(data.username)}/${token}`, data),

  fetchList: ({ token, username }: OrdersReq) => GET<Response<Order[]>>(`${BASE_URL(username)}/${token}/with_related`),

  updateItem: ({ token, username }, data) => PUT<Response<Order>>(`${BASE_URL(username)}/${token}`, data),

  resendEmail: (username: string, token: string) => POST(`${BASE_URL(username)}/${token}/resend_email`),

  sendDealPaymentEmail: (username: string, token: string) =>
    POST(`${BASE_URL(username)}/${token}/send_deal_payment_email`),

  sendDealBindingOfferEmail: (username: string, token: string) =>
    POST(`${BASE_URL(username)}/${token}/send_binding_offer_email`),

  newOrder: (
    username: string,
    productSlug: string,
    params: { is_preview?: string; token?: string; ip?: string }
  ): Promise<Response<NewOrder>> =>
    GET(`${BASE_URL(username)}/new`, {
      productSlug,
      ...params,
    }),

  createOrder: (username, data) => POST<CreateOrderResponse>(`${BASE_URL(username)}`, data),

  createAndCharge: (username, data) =>
    POST<ShallowResponse<ChargeOrderResp>>(`${BASE_URL(username)}/create_and_charge`, data),

  manageOrder: (username: string, token: string, data: { expand: string[] }, accessToken?: string) =>
    GET<ShallowResponse<ManageOrder>>(`${BASE_URL(username)}/${token}/manage`, { ...data, token: accessToken }),

  fetchTicketUpgrade: (token: string, data: { username: string }) =>
    GET<ShallowResponse<TicketUpgradeData>>(`${BASE_URL(data.username)}/${token}/ticket/upgrade_options`, data),

  chargeOrder: (username: string, token: string, data: { toPay?: string }) =>
    POST<ShallowResponse<ChargeOrderResp>>(`${BASE_URL(username)}/${token}/charge`, data),

  cancelOrder: (username: string, token: string) =>
    POST<ShallowResponse<Order>>(`${BASE_URL(username)}/${token}/cancel`),

  continueOrder: (username: string, token: string) =>
    POST<ShallowResponse<Order>>(`${BASE_URL(username)}/${token}/continue`),

  changeOrderMethod: (
    username: string,
    token: string,
    data: {
      transfer: {
        creditCard: string | { num: string }
        form: string
        iban: string
      }
    }
  ) => POST<ShallowResponse<ChangeOrderResp>>(`${BASE_URL(username)}/${token}/change_method`, data),

  changeOrderPlan: (username: string, token: string, data: { pricingPlanId: number }) =>
    POST<ShallowResponse<ChangeOrderResp>>(`${BASE_URL(username)}/${token}/change_plan`, data),

  checkCoupon: (username, productSlug, coupon) =>
    POST(`${BASE_URL(username)}/check_coupon`, {
      productSlug,
      coupon,
    }),

  markPsAsTracked: (username, orderId) => PUT(`${BASE_URL(username)}/${orderId}/track`),

  buildOrder: (username, data, isNewSummaryPrices?: boolean) =>
    POST(`${BASE_URL(username)}/build${isNewSummaryPrices ? '?with_checkout_summary=true' : ''}`, data),
})

export type OrdersApi = ReturnType<typeof createOrdersApi>

const api = createOrdersApi(apiClient)
export const manageOrder = api.manageOrder
